@import 'Vars.scss';

.search-address__list {
  position: absolute;
  max-height: 100px;
  overflow-y: scroll;
  width: 100%;
  z-index: 1;
  .search-address__list-item {
    button {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.8rem;
        color: $text-secondary;
    }
  }
}
