@import "../../styles/Vars.scss";

.tile {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: $third;
  transition: 0.2s;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px;
  min-height: 4.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $accent,
}

.tile:hover {
  border-color: $accent
}

.tile-danger {
  border-color: $primary !important;
}

