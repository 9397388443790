@import "Vars.scss";

.credential-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 400px;

    .credential {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 300px;
        height: 180px;
        margin: 1rem;

        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25), 0px 10px 12px rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        @media only screen and (min-width: $tablet) {
            max-width: 500px;
            height: 230px;
        }

        .credential-title {
            padding: 0.5rem;

            @media only screen and (min-width: $tablet) {
                padding: 1rem;
            }

            h5 {
                font-size: 0.8rem !important;

                @media only screen and (min-width: $tablet) {
                    font-size: 1.2rem !important;
                }

            }

            .title1 {
                border-top: 3px solid #E31A3A;

            }

            .title2 {
                font-weight: 700;
                margin-bottom: 0;
                border-bottom: 3px solid #E31A3A;

            }


        }

        .credential-body {
            display: flex;
            flex-direction: column;
            height: 30%;
            padding: 0.5rem;

            @media only screen and (min-width: $tablet) {
                padding: 1rem;
            }

            span {
                font-size: 0.8rem !important;

                @media only screen and (min-width: $tablet) {
                    font-size: 1rem !important;
                }
            }

        }

        .credential-footer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: end;
            height: 30%;
            width: 100%;

            .credential-footer_border {
                height: 70%;
                background-color: #27bff0;
                width: 100%;
                border-radius: 0 0 20px 20px;
            }

            .credential-footer_img-container {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                @media only screen and (min-width: $tablet) {
                    width: 80px;
                    height: 80px;
                }
            }

            .credential-footer_img {
                width: 40px;

                @media only screen and (min-width: $tablet) {
                    width: 80px;
                }
            }
        }
    }
}