@import "Vars.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
  font-size: 18px;

  @media only screen and (min-width: $desktop) {
    font-size: 16px;
  }
}
html {
  background-color: $third;
  height: 100%;
}
body {
  color: $text-primary !important;
}
a {
  text-decoration: none !important;
}
.z-index-0 {
  z-index: 0;
}
.z-index-10 {
  z-index: 10;
}
