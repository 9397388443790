@import "Vars.scss";

.sidebar {
  position: fixed;
  top: 3rem;
  width: 15rem;
  height: calc(100vh - 3rem - 36.19px);
  border-right: 1px solid $secondary;
  background-color: $third;
  color: $text-secondary;
  font-size: 0.8rem;
  transition: transform 0.2s;
  z-index: 3;
  box-shadow: $shadow-primary;
  ul {
    list-style: none;
    padding: 0rem;
    .sidebarArea {
      font-size: 0.8rem;
      color: $text-third;
      text-transform: uppercase;
      padding-left: 0.2rem;
    }
    li {
      margin-bottom: 0rem;
      a {
        display: flex;
        border-radius: 8px;
        align-items: center;
        color: $text-secondary;
        font-weight: lighter;
        text-decoration: none;
        padding: 1rem 0 1rem 0.8rem;
        .menu-icon {
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }
      }
      a:hover {
        background-color: #5a626827;
      }
      @keyframes fade {
        0% {
          transform: translateX(-100%);
        }
        90% {
          transform: translateX(0%);
        }
      }
    }
  }
  .sidebar-acordeon__container {
    height: 0;
    overflow: hidden;
    transition: 0.5s ease-out;
    // background-color: $secondary-light;
    span {
      padding-left: 1.8rem;
      padding-right: 0.7rem;
    }
  }

  .active-acordeon {
    overflow: visible;
    height: auto;
  }

  .rotate {
    .menu-icon {
      transition: 0.2s;
      transform: rotate(-180deg);
    }
  }

  .norotate {
    .menu-icon {
      transition: 0.2s;
      transform: rotate(0deg);
    }
  }
}

.close {
  transform: translateX(-100%);
}

.show {
  transform: translateX(0);
}

.activeItem {
  color: $text-primary !important;
  font-weight: bold !important;
  background-color: #eeeeee;
  animation: fade 0.2s;
  a {
    color: $text-light !important;
  }
}
