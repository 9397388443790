@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

$primary-font: 'Roboto', sans-serif;

$primary: #E9002D;
$primary-dark: #bd0026;
$secondary: #c7c8c9;
$secondary-light: #c5c5c56b;
$third: #ffffff;
$accent: #929292;
$background-main: #f7f7f7;
$text-primary: #2e2f30;
$text-secondary: #5a6268;
$text-third: #a7a6a6;
$text-light: #f8f8f8;
$success: #007bff; //bootstrap

// Media Queries
$mobile: 320px;
$small: 576px;
$tablet: 768px;
$desktop: 992px;
$desktop-pro: 1200px;
$desktop-pro-xl: 1400px;

// Shadows
$shadow-primary: 0 0 5px 2.25px rgba(0, 0, 0, 0.192);
$shadow-secondary: 1px 3px 7px 0px rgb(0 0 0 / 19%);
$shadow-third: 1px 3px 7px 0px rgb(0 0 0 / 19%);
$shadow-four: 1px 1px 4px 0px rgb(0 0 0 / 19%);
$shadow-focus: 0 0 0 0.25rem #fd214d6b;