@import "Vars.scss";

.active {
  // background-color: $accent;
  color: $text-primary !important;
  font-weight: bold !important;
  animation: fade 0.2s;
  a {
    color: $text-light !important;
  }
}
.active-link {
  background-color: $primary-dark;
}
.btn-check:focus + .btn,
.btn:focus,
.btn-close:focus {
  outline: 0;
  box-shadow: none;
}
.btn-check:active + .btn,
.btn:active,
.btn-close:active {
  outline: 0;
  background-color: rgba(0, 0, 0, 0.116);
}
.z-index-1 {
  z-index: 1;
}
// background LOGIN /REGISTER
.bg-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  height: auto;
  min-height: calc(100vh - 3rem);
  width: 100%;
  position: relative;
  display: block;
  @media only screen and (min-width: $desktop) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: $mobile) {
    padding: 10px;
  }
}

@media only screen and (min-width: $desktop) {
  .bg1 {
    background-image: url(../assets/images/background-pplr-1.jpeg);
  }
  .bg2 {
    background-image: url(../assets/images/background-pplr-2.jpeg);
  }
  .bg3 {
    background-image: url(../assets/images/background-pplr-3.jpeg);
  }
  .bg4 {
    background-image: url(../assets/images/background-pplr-4.jpeg);
  }
  .bg5 {
    background-image: url(../assets/images/background-pplr-5.jpeg);
  }
}

@media only screen and (min-width: $mobile) {
  .bg1 {
    background-image: url(../assets/images/background-pplr-1.jpeg);
  }
  .bg2 {
    background-image: url(../assets/images/background-pplr-2.jpeg);
  }
  .bg3 {
    background-image: url(../assets/images/background-pplr-3.jpeg);
  }
  .bg4 {
    background-image: url(../assets/images/background-pplr-4.jpeg);
  }
  .bg5 {
    background-image: url(../assets/images/background-pplr-5.jpeg);
  }
}

.cont {
  // display: flex;
  // justify-content: center !important;
  // align-items: center !important;
  // padding: 1.5rem;
  width: auto !important;
  height: auto !important;
  // background-color: $third;
  // box-shadow: $shadow-primary;
  // min-height: 30rem;
  // border-radius: 5px;
  @media only screen and (min-width: $desktop) {
    min-width: 30rem !important;
  }
}

.bg-third {
  background-color: $third;
}
// Loader
.loader_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: #ffffffab;
  z-index: 2;
  border-radius: 10px;
}
// NAvbar
.navbar {
  background-color: $primary;
  color: $text-light;
  height: 3rem;
  box-shadow: $shadow-primary;

  .nav-link {
    font-size: 0.8rem;
    color: $text-light;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    a {
      color: $text-primary;
    }
  }
  .navbar_dropdown-title {
    p {
      font-size: 0.8rem;
    }
  }
  .user-icon {
    width: 1.4rem;
    height: 1.4rem;
    font-size: 1.2rem;
    margin: 0 0.2rem 0 0.5rem;
  }
  .dropdown-menu[data-bs-popper] {
    right: 0;
    left: auto;
    min-width: 200px;
  }
}
.container-block {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.384);
  z-index: 2;
  @media only screen and (min-width: $desktop) {
    position: relative;
    width: 0vw;
    height: 0vh;
  }
}
// footer

.main-footer {
  padding: 0.5rem 0;
  bottom: 0;
  position: relative;
  width: 100%;
  z-index: 1000;
  @media only screen and (min-width: $desktop) {
    position: fixed;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}
.footer-private {
  background-color: $third;
  color: $text-secondary;
  box-shadow: $shadow-four;
  border-top: 1px solid $secondary;
}
.footer-public {
  background-color: $third;
  color: $text-secondary;
  box-shadow: $shadow-four;
  @media only screen and (min-width: $desktop) {
    background-color: #ffffff00;
    color: $text-light;
    box-shadow: none;
  }
}
// Main container
.main-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 3rem;
  width: 100%;
  // min-height: calc(100vh - 3rem);
  // min-height: calc(100vh - 3rem);
  background-color: $background-main;
}
.user-header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 3rem;
  height: auto;
  min-height: 3.5rem;
  width: 100%;
  background-color: $third;
  color: $text-primary;
  border-bottom: 1px solid $secondary;
  z-index: 1;
  .user-header__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // padding-left: 0;
    @media only screen and (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    @media only screen and (min-width: $desktop) {
      padding-left: 15rem;
    }
  }
  a {
    padding: 0;
    font-size: 0.8rem;
    padding-left: 1rem;
    line-height: 1rem;
    color: $text-secondary;
  }
  #basic-nav-dropdown:focus {
    color: $text-primary;
  }
  #basic-nav-dropdown:hover {
    color: $text-primary;
  }
  .dropdown-item {
    font-size: 1rem;
    color: $text-primary;
  }
  p,
  span {
    font-size: 0.8rem;
    @media only screen and (min-width: $tablet) {
      font-size: 1rem;
    }
  }
}
.dropdown-item:active {
  background-color: $success;
  color: $text-light;
}
.user-container {
  margin-top: 56px;
  .dropdown-menu[data-bs-popper] {
    left: -70%;
  }
}
.user-admin-container {
  .dropdown-menu[data-bs-popper] {
    left: -70%;
  }
}
.top-user-header {
  // top: 3rem;
}
.patient-container,
.admin-container {
  width: 100%;
  height: calc(100vh - 140px);
  padding: 0.5rem;
  z-index: 1031;
  @media only screen and (min-width: $desktop) {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}

.admin2-container {
  width: 100%;
  height: calc(100vh - 84px);
  padding: 0.5rem;
  z-index: 1031;
  @media only screen and (min-width: $desktop) {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}

// Forms
.form-control,
.form-select {
  background-color: $third;
  // border: none;
  // border-bottom: 1px solid $text-primary;
  color: $text-secondary;
  // padding: 0.1rem 2.25rem 0.1rem 0.75rem;
}
.form-control:focus,
.form-select:focus {
  background-color: $third;
  border: none;
  border-bottom: 1px solid $text-primary;
  box-shadow: $shadow-focus;
}
.form-label {
  color: $text-secondary;
  font-size: 1rem;
  a {
    font-size: 1rem;
  }
}
// Register
.form_register {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .col-12,
  .col-6 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.circle-step {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 50%;
  background-color: $primary;
  color: $text-light;
  margin-right: 1rem;
  border: 3px solid #ff5f7f;
}
.title-step {
  color: $primary;
  margin: 0;
}
.step-inactive {
  opacity: 0.5;
  transform: scale(0.9);
}
// Iconos y botones
.btn-icon,
.btn-close {
  transition: 0.2s;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 50%;
  padding: 0;
  margin-left: 0.5rem;
}
.btn-close:hover {
  background-color: rgba(128, 128, 128, 0.452);
}
.dangger:hover {
  background-color: #dc354657;
}
.success:hover {
  background-color: #19875444;
}
.primary:hover {
  background-color: #0d6dfd46;
}
.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  padding: 0;
  color: $text-primary;
  height: 2.6rem;
  width: 2.6rem;
  border-radius: 50%;
}
.menu-icon {
  font-size: 1.3rem;
  // transition: 0.2s;
}

.icon_container {
  position: relative;
  display: inline-block;
  .notification_icon {
    color: $text-secondary;
    border: 0.5px;
    font-size: 1.2rem;
  }
  .notification_circle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: -8px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $primary;

    span {
      color: $third;
      font-size: 0.8rem;
      padding: 0.6rem;
    }
  }
}
// Mensaje de error

.error-form {
  transition: 0.2s;
  display: flex;
  color: $primary;
  p {
    display: flex;
    font-size: 0.8rem;
    margin: 0;
  }

  .show {
    display: block;
  }
  .close {
    display: none;
  }
}
// GEneral
// Main
.main {
  .main__banner {
    width: 100%;
  }
  .main__title {
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    color: $accent;
    span {
      font-size: calc(1.325rem + 0.9vw);
      color: $primary;
    }
  }
  .main__card {
    position: relative;
    height: 7rem;
    max-width: 270px;
    min-width: 270px;
    margin: 1rem;
    padding: 0;
    background-color: $third;
    transition: 0.2s;
    box-shadow: $shadow-four;
    border-radius: 20px;
    a {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 1rem;
      border-radius: 20px;
      font-weight: lighter;
      .main__icon {
        font-size: 4rem;
      }
      h5 {
        margin: 0;
        padding-left: 1rem;
        font-size: 1.5rem;
      }
    }
  }
}
.main__card:hover {
  transform: scale(1.1);
}
.switch-container {
  overflow-x: scroll;
  white-space: nowrap;
  @media only screen and (min-width: $desktop) {
    overflow: auto;
    white-space: normal;
  }
  a {
    display: inline-block;
    min-width: max-content;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
    transition: 0.2s;
    color: $text-third;
  }
  a:hover {
    transform: scale(1.1);
  }
  a:focus {
    outline: 0;
  }
  .active-switch {
    color: $text-secondary;
    font-weight: 700;
  }
}
.section-title {
  text-transform: uppercase;
  color: $primary;
}
// Perfil del Paciente
.perfil-paciente {
  .datos-paciente__title {
    font-size: 0.8rem;
    color: $text-third;
    text-transform: uppercase;
  }
  .datos-paciente__label {
    // display: inline-block;
    // width: fit-content;
    margin: 0;
    margin-right: 1rem;
    font-weight: lighter;
    text-transform: capitalize;
  }
}
// Grupo familiar
.patient {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: $third;
  box-shadow: $shadow-four;
  transition: 0.2s;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px;

  @media only screen and (min-width: $tablet) {
    justify-content: space-between;
    height: 5.5rem;
  }
  div {
    display: flex;
    align-items: center;
  }
  .patient-name {
    // width: 100%;
    // height: 100%;
    margin: 0 0 1rem 0;
    @media only screen and (min-width: $tablet) {
      margin: 0;
    }
    h6 {
      font-weight: 700;
    }
  }
  .patient-actions {
    justify-content: space-around;
    @media only screen and (min-width: $tablet) {
      justify-content: flex-end;
    }
    button {
      font-size: 0.8rem;
      transition: 0.2s;
      margin-left: 0.5rem;
    }
    button:hover {
      transform: scale(1.1);
    }
  }
}

.patientContainer {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

// Historial Clinica
.historia-clinica {
  .switch-container__hc {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin: 0.5rem;
    height: auto;
    min-height: 200px;
    background-color: $text-light;
    box-shadow: $shadow-primary;
  }
}
// Notificaciones y mensajes
.mensaje_container,
.message_container {
  transition: 0.5s;
  cursor: pointer;
  p {
    margin: 0;
  }
  .mensaje_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    @media only screen and (min-width: $tablet) {
      padding: auto;
    }
  }
  .mensaje_text,
  .message_text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .asunto {
    color: $text-primary;
  }
  .mensaje,
  .receiver {
    color: $text-secondary;
    font-size: 0.8rem;
  }
}
.mensaje_container:hover,
.message_container:hover {
  background-color: $background-main;
}
// Tooltip
.my-tooltip {
  position: relative;
}
.my-tooltip .tiptext {
  visibility: hidden;
  text-align: center;
  position: absolute;
  width: max-content;
  display: none;
  bottom: -50%;
  z-index: 1;
  border-radius: 3px;
  font-size: 0.8rem;
  padding: 0.4rem;
  background-color: $text-primary;
  color: $text-light;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.my-tooltip:hover .tiptext {
  visibility: hidden;
  display: none;
  @media only screen and (min-width: $tablet) {
    visibility: visible;
    display: block;
  }
}
// section-content
.section-contennt {
  padding-top: 1rem;
}
// admin
.admin-header-text {
  color: $text-light !important;
}

//adminPatients
.admin-patients_text {
  font-size: 0.8rem;
  font-style: italic;
}
.admin-patients__patient,
.admin-message__message {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: $third;
  box-shadow: $shadow-four;
  cursor: pointer;
  transition: 0.2s;
  p {
    margin-bottom: 0;
  }
  .bg-1 {
    background-color: $accent;
  }
  .bg-2 {
    background-color: $success;
  }
  .bg-3 {
    background-color: $primary;
  }
}
.admin-patients__patient:hover,
.admin-message__message:hover {
  background-color: $background-main;
}
.status-container {
  display: flex;
  justify-content: center;
  min-width: 7rem;
  padding: 0.2rem;
  border-radius: 1rem;
  color: $text-light;
  cursor: pointer;
  transition: 0.2s;
  p {
    display: flex;
    font-size: 0.8rem;
    text-align: center;
  }
}
.status-container:hover {
  transform: scale(1.1);
}
.admin-patient__modal {
  .modal-content {
    min-height: 80vh;
    background-color: $background-main !important;
  }
}
.admin-patient__list {
  list-style: none;
  border: 1px solid $secondary-light;
  padding: 1rem;
  font-weight: lighter;
  li {
    margin-bottom: 0.5rem;
  }
  strong {
    text-transform: uppercase;
  }
}
.admin-patient__img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  overflow: hidden;
  border: 1px solid $secondary-light;
  border-radius: 5px;
  @media only screen and (min-width: $tablet) {
    width: 330px;
    height: 330px;
  }
  .admin-patient__img {
    max-width: 250px;
    max-height: 250px;
    @media only screen and (min-width: $tablet) {
      max-width: 330px;
      max-height: 330px;
    }
  }
}
.rotate-img {
  transform: rotate(90deg);
}
// datos institucionales
.main-telefonos {
  color: rgb(0, 0, 0);
  position: relative;
  bottom: 0;
  width: 100%;

  .container {
    box-shadow: 0 0.1rem 0.4rem rgb(0, 0, 0, 0.3);
    border-radius: 00.25rem;
  }
  section article {
    background-color: #8b8a8a;
    margin: 1rem 0;
    border-radius: 0.5rem;
    padding: 0.25rem;
  }

  .encabezado {
    font-size: 6rem;
    color: rgb(244, 246, 248);
    margin: 0 auto;
    border-radius: 0.5rem;
    background-color: $primary;
    padding: 0.25rem 1rem;
    box-shadow: 0 0.1rem 0.4rem rgb(0, 0, 0, 0.3);
  }

  .list-unstyled {
    transition: all 0.3s ease;
    font-size: 4rem;
    background-color: rgb(247, 242, 238);
    margin: 1rem 0;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 0.1rem 0.4rem rgb(0, 0, 0, 0.3);
    cursor: pointer;
  }
}
// establecimientos modal
.est-list {
  background-color: $secondary-light;
  min-height: 48px;
  width: 100%;
  padding: 0.5rem;
  span {
    font-size: 14px;
  }
}
.backdrop-class {
  opacity: 1;
  z-index: 1055 !important;
}
.modal-body-height {
  max-height: 500px;
  min-height: 500px;
}
.selector {
  max-height: 400px;
  min-height: 400px;
  overflow-y: scroll;
  border: 0.1px solid $secondary-light;

  .selector-li:hover {
    background-color: $secondary-light;
  }
}

// establecimiento card
.card-institution {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
  background-color: $third;
  box-shadow: $shadow-four;
  transition: 0.2s;

  @media only screen and (min-width: $tablet) {
    justify-content: space-between;
    // height: 5.5rem;
  }
}
.select-institution {
  select {
    min-height: 36px !important;
    border: 1px solid gray !important;
  }
}
.select-institution {
  select:focus {
    box-shadow: none !important;
  }
}

.form_establecimiento {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .col-12,
  .col-6 {
    display: flex;
    flex-direction: column;
  }
}

.text-responsive {
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count-number {
  font-size: 3rem;
}

.textSmaller {
  font-size: 0.8rem;
}

/** confirmAppointment */
.confirmAppointment{
  margin-top: 30px;
  text-align: start;
}

/** Appointment */

.appointment-card {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
  min-height: 120px;
  box-shadow: $shadow-four;
  transition: 0.2s;
}


.position-sticky {
  position: sticky !important;
}

.bg-secondary-light {
  background-color: $secondary-light;
}

.imageSizeEmpty {
  width: 180px;
  margin-bottom: 1.5rem;
}
