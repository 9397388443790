@import "../../styles/Vars.scss";

.BasicShadow {
  border: 1px solid $secondary;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
  border-radius: 8px;
}

.titleIcon {
  svg {
    font-size: x-large;
  }
}

.iconModification {
  font-size: 0px !important;
  height: 100%;
}
