
.in {
  animation: in;
  animation-duration: 0.5s;
}
.in-fast {
  animation: in;
  animation-duration: 0.2s;
}
.out {
  opacity: 0;
  transition: 0.5s;
  transform: translateY(40px);
}
@keyframes in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  50% {
    opacity: 1;
  }
  80% {
    transform: translateY(0px);
  }
}

.in-error{
  animation: inError;
  animation-duration: 0.2s;
}

@keyframes inError {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
  }
  80% {
    transform: translateY(0px);
  }
}